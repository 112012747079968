<template>
  <v-container fluid>
    <div class="home">
      <v-img alt="Clever" src="@/assets/welcome.jpg" contain/>
    </div>
  </v-container>
</template>
<script>

export default {
  name: 'dictionaries',

  data: () => ({
    //
  }),
};
</script>
<style>
.home {
  max-width: 80vw;
}
</style>
